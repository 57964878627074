<template>
	<b-sidebar v-model="visible" no-close-on-route-change shadow :title="title" sidebar-class="b-sidebar">
		<template #footer="{ hide }">
			<b-card>
				<p class="row justify-content-center">
					<b-button variant="success" size="lg" @click="back">Gå tilbage</b-button> &nbsp;
					<b-button variant="primary" size="lg" @click="hide">Luk</b-button>
				</p>
			</b-card>
		</template>
		<b-nav vertical>
			<p class="row justify-content-center">
				{{ undertitle }}
			</p>
			<br />
			<br />
			<b-nav-item
				v-for="item in nav"
				:key="item.name"
				:class="{ 'active-nav-item': isActiveNavItem(item) }"
				v-if="!item.hidden"
				:to="{ name: item.name, params: item.params }"
				@click="routerClick(item.name)"
			>
				<div class="icon-text-container">
					<font-awesome-icon :icon="item.icon" class="icon fa-fw" size="lg" />
					<span class="text">{{ item.text }}</span>
				</div>
			</b-nav-item>
		</b-nav>
	</b-sidebar>
</template>

<script>
export default {
	name: 'NavigationSidebar',
	props: ['nav', 'value', 'title', 'undertitle'],
	computed: {
		routerAction() {
			return this.$route.meta.action
		},
		routerResource() {
			return this.$route.meta.resource
		},
		visible: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
	methods: {
		back() {
			// Go to the previous page
			// Hide the sidebar
			this.visible = false

			// Redirect to companyEmployeeList
			this.$router.push({ name: 'CompanyEmployeeList', params: { companyId: this.$route.params.companyId } })
		},
		isActiveNavItem(item) {
			// Check if the item's route matches the current route
			return this.$route.name === item.name
		},
		routerClick(event) {
			if (event === 'CompanyEmployeeList') {
				this.visible = false
			}
		},
	},
}
</script>

<style lang="scss">
@import '@/styles/components/navigation.scss';
</style>

<style scoped>
/* Icon styling */
.icon {
	display: inline-block; /* Ensures the icon behaves like a block element with width and height */
	width: 30px; /* Adjust this value to fit the widest icon */
	text-align: center; /* Centers the icon within its fixed width */
}

.text {
	font-size: 18px;
	margin-left: 5px;
	padding: 5px;
	
}
</style>
