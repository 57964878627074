<template>
	<section :class="{ 'container-fluid': true, 'sidebar-open': sidebarVisible }">
		<div>
			<b-button @click="setSidebar" v-if="!sidebarVisible" variant="success">Vis menu</b-button>
		</div>
		<div class="row justify-content-center">
			<div class="col-lg-10 col-md-11 col-12">
				<h1>{{ companyName }}</h1>
				<p>{{ companyCvr }}</p>
				<h2><b-badge variant="warning" v-if="!isActive">Virksomheden er inaktivt</b-badge></h2>
			</div>
		</div>

		<NavigationSlide
			:title="companyName"
			:undertitle="companyCvr"
			v-model="sidebarVisible"
			:nav="[
				{
					name: 'Company',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.info'),
					icon: 'building',
					hidden: !$can('read', 'Company', 'CompanyInfo'),
				},
				{
					name: 'CompanyEmployeeList',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.employeeList'),
					hidden: !$can('read', 'Company', 'Employees'),
					icon: 'users',
				},
				{
					name: 'CompanyPensionList',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.pension'),
					icon: 'list-alt',
					hidden: !$can('read', 'Company', 'Pensions'),
				},
				{
					name: 'CompanyInsuranceList',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.insurance'),
					icon: 'house-damage',
					hidden: !$can('read', 'Company', 'Insurances'),
				},
				{
					name: 'CompanyGroup',
					params: '{ companyId: ' + id + '}',
					text: 'Grupper',
					icon: 'user-friends',
					hidden: !$can('read', 'Company', 'Group'),
				},

				{
					name: 'CompanyKycList',
					params: '{ companyId: ' + id + '}',
					text: 'Ejerforhold',
					hidden: !$can('read', 'Company', 'CompanyAdmins'),
					icon: 'passport',
				},

				{
					name: 'CompanyAdvisor',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.advisors'),
					icon: 'user-graduate',
					hidden: !$can('read', 'Company', 'CompanyAdvisors'),
				},

				{
					name: 'CompanyLogs',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.logs'),
					icon: 'server',
					hidden: !$can('read', 'Company', 'Logs'),
				},
				{
					name: 'CompanySettings',
					params: '{ companyId: ' + id + '}',
					text: $t('companyBase.settings'),
					icon: 'cog',
					hidden: !$can('update', 'Company', 'Settings'),
				},
			]"
		/>

		<div class="row" v-if="isReady">
			<div class="col">
				<router-view />
			</div>
		</div>
	</section>
</template>

<script>
import NavigationSidebar from '@/modules/global/components/NavigationSidebar.vue'
import NavigationSlide from '../../../global/components/NavigationSlide.vue'
export default {
	name: 'BaseCompany',
	components: { NavigationSidebar, NavigationSlide },
	async created() {
		await this.$store.dispatch('companyVuex/reloadCompany', this.id)
		this.$emit('updateLoader')
	},
	data() {
		return {
			sidebarVisible: true,
		}
	},
	computed: {
		companyName() {
			return this.company?.companyInfo?.name ?? ' '
		},
		companyCvr() {
			return this.company?.companyInfo?.cvr ?? ' '
		},
		id() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		isReady() {
			return this.$store.getters['companyVuex/isReady']
		},
		isActive() {
			if (this.company?.hasOwnProperty('isActive')) {
				return this.company?.isActive
			}else{
				return true
			}
		},
	},
	destroyed() {
		this.$store.dispatch('companyVuex/clearState')
	},
	methods: {
		setSidebar() {
			this.sidebarVisible = !this.sidebarVisible
		},
	},
}
</script>

<style scoped></style>
