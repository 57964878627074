<template>
  <div class="navigation-slide row justify-content-center">
    <template v-for="item in nav">
      <div class="col-auto nav-item" :key="item.name" v-if="!item.hidden">
        <router-link :to="{ name: item.name, params: item.params }" class="nav-link">
          <h5>
            <b>
              <font-awesome-icon :icon="['fa', item.icon]" class="icon" />
              {{ item.text }}
            </b>
          </h5>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NavigationSlide",
  props: ["nav"],
  computed: {
    routerAction() {
      return this.$route.meta.action  
    },
    routerResource() {
      return this.$route.meta.resource
    },
  },
};
</script>

<style scoped>
.navigation-slide {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;

}

.nav-item {
  margin: 5px;
}

.nav-link {
  text-decoration: none;
  color: #343a40;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

.icon {
  margin-right: 4px;
}

h5 {
  margin: 0;
  font-size: 1.1rem;
}

b {
  font-weight: 600;
}
</style>